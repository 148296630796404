import React, { useState, useEffect } from 'react'
import { FormSpy } from 'react-final-form'
import { diff } from 'deep-object-diff'

const WithAutoSaveDebounceComponent = props => {
	const [values, setValues] = useState(props.values)
	let promise = null
	let timeout = null

	const save = async () => {
		if (promise) await promise

		const { save, errors } = props

		if (Object.keys(errors).length) return false

		// Этот шаг сравнения является необязательным
		const difference = diff(values, props.values)
		if (!Object.keys(difference).length) return false

		setValues(props.values)
		promise = save(props.values)
		await promise
		promise = null
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		timeout = setTimeout(save, props.debounce)
		return () => clearTimeout(timeout)
	})

	return null
}

const WithAutoSaveDebounce = props => (
	<FormSpy
		{...props}
		subscription={{
			values: true,
			errors: true,
		}}
		component={WithAutoSaveDebounceComponent}
	/>
)

export default WithAutoSaveDebounce
