/**
 * Номер месяца переданной даты с ведущим нулем
 * @param date
 * @returns {*}
 */
export const getFullMonth = date => {
	let m = date.getMonth() + 1
	return m < 10 ? '0' + m : m.toString()
}

/**
 * Номер дня переданной даты с ведущим нулем
 * @param date
 * @returns {string}
 */
export const getFullDay = date => {
	let d = date.getDate()
	return d < 10 ? '0' + d : d.toString()
}

/**
 * Дата
 * @param d - дни
 * @param h - часы
 * @param m - минуты
 * @param s - секунды
 * @returns {Date}
 */
export const getDate = ({ d, h, m, s }) => {
	const date = new Date()
	if (d) date.setDate(date.getDate() + d)
	if (h) date.setHours(date.getHours() + h)
	if (m) date.setMinutes(date.getMinutes() + m)
	if (s) date.setSeconds(date.getSeconds() + s)
	return date
}
