import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import objectsDiff from 'utils/objectsDiff'
import Icon from 'components/Icon'
import HelperButton from 'components/HelperButton'
import AdaptersError from '../components/Error'
import Secondary from 'components/Secondary'
import MaskedInput from 'components/MaskedInput'
import emailMask from 'text-mask-addons/dist/emailMask'
import lodash from 'utils/lodash'
import css from './AdapterText.module.scss'

const b = bem.adapterText(css)

const Input = props => <input {...props} />
const masks = {
	date: [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/],
	phone: [/[78]/, ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
	// Следующая маска ошибочно работает при вставке скопированного номера
	// phone: ['+', '7', ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
	email: emailMask,
}

/**
 * Использование
 *
 <Field
 component={AdapterText}
 name="url"
 label="url"
 placeholder="Адрес"
 autoComplete="off"
 validate={validators.compose(
		validators.required,
		validators.number,
		validators.min(0)
	)}
 onChange={e => console.log(e.target.value)}
 onFocus={e => console.log(e.target.value)}
 onBlur={e => console.log(e.target.value)}
 />
 */
const AdapterText = props => {
	const _props = lodash.pickBy(props, (v, k) => ['hasFeedback'].indexOf(k) === -1)

	const {
		noMargin,
		noError,
		onChange,
		onFocus,
		onBlur,
		required,
		input,
		meta,
		label,
		helper,
		isHelperStatic,
		negative,
		prefix,
		size,
		mask,
		relative,
		...rest
	} = _props

	const [isHelp, setIsHelp] = useState(isHelperStatic)
	const isPrefix = !!prefix
	const isError = meta.error && meta.touched && !noError
	const Tag = mask ? MaskedInput : Input

	return (
		<div
			className={b({
				'is-prefix': isPrefix,
				'is-error': isError,
				'is-no-margin': noMargin,
				'is-negative': negative,
				'is-help-showed': isHelp,
				size,
			})}
		>
			<label className={b('label')}>
				{!!(label || helper) && (
					<span className={b('caption')}>
						{label}
						{required && (
							<>
								<span className={b('star')}>*</span>
							</>
						)}
						{helper && !isHelperStatic && (
							<HelperButton onClick={() => setIsHelp(!isHelp)} active={isHelp} negative={negative} />
						)}
					</span>
				)}
				<span className={b('wrap')}>
					{prefix && (
						<span className={b('prefix')}>
							<Icon type={prefix} />
						</span>
					)}
					<Tag
						className={b('field', { relative })}
						{...input}
						{...rest}
						aria-label={label || props.placeholder}
						mask={mask && masks[mask] ? masks[mask] : mask}
						onChange={e => {
							input.onChange(e)
							if (onChange) onChange(e)
						}}
						onFocus={e => {
							input.onFocus(e)
							if (onFocus) onFocus(e)
						}}
						onBlur={e => {
							input.onBlur(e)
							if (onBlur) onBlur(e)
						}}
					/>
				</span>
			</label>
			{isError && (
				<AdaptersError className={b('error')} negative={negative}>
					{meta.error}
				</AdaptersError>
			)}
			<Secondary className={b('help', { active: isHelp })} negative={negative}>
				<div dangerouslySetInnerHTML={{ __html: helper }} />
			</Secondary>
		</div>
	)
}

AdapterText.propTypes = {
	noMargin: PropTypes.bool,
	noError: PropTypes.bool,
	negative: PropTypes.bool,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	required: PropTypes.bool,
	label: PropTypes.string,
	helper: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	isHelperStatic: PropTypes.bool,
	prefix: PropTypes.string,
	size: PropTypes.oneOf(['lg', 'sm']),
	mask: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	relative: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
}

export default memo(AdapterText, (prevProps, nextProps) => {
	if (prevProps.input.value !== nextProps.input.value) return false
	if (prevProps.disabled !== nextProps.disabled) return false
	if (prevProps.prefix !== nextProps.prefix) return false
	if (objectsDiff(prevProps.meta, nextProps.meta)) return false
	return true
})
