import React, { useState } from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import './AdapterSlider.scss'
import css from './AdapterSlider.module.scss'
import Slider from 'components/RcSlider'
import { numberFormat } from 'utils/numberFormat'

const b = bem.adapterSlider(css)

const AdapterSlider = props => {
	const {
		form,
		values,
		min,
		max,
		step,
		input: { name },
		onChange,
		negative,
		noMargin,
	} = props
	const [value, setValue] = useState(values[name] || 0)
	return (
		<div
			className={b({
				'is-negative': negative,
				'is-no-margin': noMargin,
			})}
		>
			<Slider
				min={min}
				max={max}
				step={step}
				defaultValue={value}
				marks={{
					[min]: numberFormat(min),
					[max]: numberFormat(max),
				}}
				onChange={v => {
					setValue(v)
					form.change(name, v)
					if (onChange) onChange(v)
				}}
			/>
		</div>
	)
}

AdapterSlider.propTypes = {
	form: PropTypes.object.isRequired,
	values: PropTypes.object.isRequired,
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
	step: PropTypes.number,
	onChange: PropTypes.func,
	negative: PropTypes.bool,
	noMargin: PropTypes.bool,
}

export default AdapterSlider
