import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './FormMessage.module.scss'
import Icon from 'components/Icon'

const b = bem.formMessage(css)

const FormMessage = props => {
	const { as: Tag, children, success, error, className, negative, isIcon, ...rest } = props
	let icon = null
	if (success) icon = 'check'
	if (error) icon = 'sign'
	return (
		<Tag className={b({ success, error, negative }, className)} {...rest}>
			{isIcon && <Icon className={b('icon')} type={icon} size={14} />}
			{children}
		</Tag>
	)
}

FormMessage.defaultProps = {
	as: 'p',
}

FormMessage.propTypes = {
	as: PropTypes.oneOf(['p', 'div', 'span']),
	negative: PropTypes.bool,
	success: PropTypes.bool,
	error: PropTypes.bool,
	isIcon: PropTypes.bool,
}

export default FormMessage
