import { useEffect, useState } from 'react'

/**
 * Установка флага активности через задержку
 * @param value
 * @param delay
 * @returns {boolean}
 */
const useDeferredActive = (value, delay) => {
	const [isActive, setIsActive] = useState(false)

	useEffect(() => {
		let timeout
		if (value) {
			timeout = setTimeout(() => setIsActive(true), delay)
		}
		if (!value) {
			clearTimeout(timeout)
			setIsActive(false)
		}
		return () => clearTimeout(timeout)
	}, [value, delay])

	return isActive
}

export default useDeferredActive
