import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import objectsDiff from 'utils/objectsDiff'
import HelperButton from 'components/HelperButton'
import AdaptersError from '../components/Error'
import Secondary from 'components/Secondary'
import lodash from 'utils/lodash'
import css from './AdapterTextArea.module.scss'
import TextareaAutosize from 'components/TextareaAutosize'

const b = bem.adapterTextarea(css)

/**
 * Использование
 *
 <Field
 component={AdapterTextArea}
 name="url"
 label="url"
 placeholder="Адрес"
 validate={validators.compose(
		validators.required,
		validators.number,
		validators.min(0)
	)}
 onChange={e => console.log(e.target.value)}
 onFocus={e => console.log(e.target.value)}
 onBlur={e => console.log(e.target.value)}
 minRows={2}
 maxRows={6}
 />
 */
const AdapterTextArea = props => {
	const _props = lodash.pickBy(props, (v, k) => ['hasFeedback'].indexOf(k) === -1)
	const {
		noMargin,
		noError,
		onChange,
		onFocus,
		onBlur,
		required,
		input,
		meta,
		label,
		helper,
		isHelperStatic,
		negative,
		size,
		...rest
	} = _props

	const [isHelp, setIsHelp] = useState(isHelperStatic)
	const isError = meta.error && meta.touched && !noError

	return (
		<div
			className={b({
				'is-error': isError,
				'is-no-margin': noMargin,
				'is-negative': negative,
				'is-help-showed': isHelp,
				size,
			})}
		>
			<label className={b('label')}>
				{!!(label || helper) && (
					<span className={b('caption')}>
						{label}
						{required && (
							<>
								<span className={b('star')}>*</span>
							</>
						)}
						{helper && !isHelperStatic && (
							<HelperButton onClick={() => setIsHelp(!isHelp)} active={isHelp} negative={negative} />
						)}
					</span>
				)}
				<div className={b('wrap')}>
					<TextareaAutosize
						className={b('field')}
						{...input}
						{...rest}
						aria-label={label || props.placeholder}
						onChange={e => {
							input.onChange(e)
							if (onChange) onChange(e)
						}}
						onFocus={e => {
							input.onFocus(e)
							if (onFocus) onFocus(e)
						}}
						onBlur={e => {
							input.onBlur(e)
							if (onBlur) onBlur(e)
						}}
					/>
				</div>
			</label>
			{isError && (
				<AdaptersError className={b('error')} negative={negative}>
					{meta.error}
				</AdaptersError>
			)}
			<Secondary className={b('help', { active: isHelp })} negative={negative}>
				<div dangerouslySetInnerHTML={{ __html: helper }} />
			</Secondary>
		</div>
	)
}

AdapterTextArea.propTypes = {
	noMargin: PropTypes.bool,
	noError: PropTypes.bool,
	negative: PropTypes.bool,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	required: PropTypes.bool,
	label: PropTypes.string,
	helper: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	isHelperStatic: PropTypes.bool,
	size: PropTypes.oneOf(['lg']),
}

export default memo(
	AdapterTextArea,
	(prevProps, nextProps) =>
		!(prevProps.input.value !== nextProps.input.value || objectsDiff(prevProps.meta, nextProps.meta))
)
