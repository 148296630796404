import React from 'react'
import PropTypes from 'prop-types'
import { JSONLD, Generic } from 'react-structured-data'
import { getDate } from 'utils/date'
import genericOrganization from 'components/Schema/Generic/organization'
import useSiteMetadata from 'hooks/useSiteMetadata'
import WithLocation from 'containers/WithLocation'

/**
 * Схема - Товар
 * @param props
 * @returns {null|*}
 * @constructor
 */
const SchemaProduct = props => {
	const site = useSiteMetadata()
	const { location, name, description, price, image, sku } = props
	if (!price || !name || !description) return null
	const { schema, siteUrl } = site

	return (
		<JSONLD dangerouslyExposeHtml>
			<Generic
				jsonldtype="Product"
				schema={{
					name,
					description,
					image,
					sku,
				}}
			>
				<Generic
					type="brand"
					jsonldtype="Brand"
					schema={{
						name: schema.name,
					}}
				/>
				<Generic
					type="offers"
					jsonldtype="Offer"
					schema={{
						price,
						priceCurrency: 'RUB',
						availability: 'https://schema.org/InStock',
						priceValidUntil: getDate({ d: 14 }),
						url: siteUrl + location.pathname,
					}}
				>
					{genericOrganization({ site, type: 'seller' })}
				</Generic>
			</Generic>
		</JSONLD>
	)
}

SchemaProduct.propTypes = {
	name: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	price: PropTypes.number.isRequired,
	image: PropTypes.string,
}

export default WithLocation(SchemaProduct)
