import React, { useState, useEffect, useContext, Fragment } from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Calc2.module.scss'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { Form, Field } from 'react-final-form'
import AdapterRadio from 'components/Adapter/Radio'
import AdapterCheckbox from 'components/Adapter/Checkbox'
import AdapterSlider from 'components/Adapter/Slider'
import AdapterText from 'components/Adapter/Text'
import Increment from 'components/Increment'
import Ruble from 'components/Ruble'
import Secondary from 'components/Secondary'
import { numberFormat } from 'utils/numberFormat'
import useSiteMetadata from 'hooks/useSiteMetadata'
import SchemaProduct from 'components/Schema/Product'
import { getCookie } from 'utils/cookies'
import ContactForm from './components/ContactForm'
import Mark from 'components/Mark'
import NotificationsContext from 'contexts/notifications'

const b = bem.calc2(css)

// Рендеринг поля
const formField = (options, formProps) => {
	const { field, priceField, priceFieldPrefix, priceFieldPostfix } = options
	const { form, values } = formProps
	const value = values[field.name]
	let checked = false
	let component = null
	switch (field.type) {
		case 'checkbox':
			checked = value === true
			component = AdapterCheckbox
			break
		case 'radio':
			checked = value === field.value
			component = AdapterRadio
			break
		case 'slider':
			checked = false
			component = AdapterSlider
			break
		case 'text':
		case 'email':
			component = AdapterText
			break
		default:
	}

	let fieldParams = { ...field, component }
	switch (field.type) {
		case 'checkbox':
		case 'radio':
			fieldParams = {
				...fieldParams,
				noMargin: true,
				children:
					// Если есть счетчик и флажок установлен
					values[options.count] && values[field.name] === true
						? `${field.children}<span class="text-nowrap">: <strong>${numberFormat(
								values[options.count]
						  )}</strong></span>`
						: field.children,
			}
			break
		case 'slider':
			fieldParams = {
				...fieldParams,
				form,
				values,
				noMargin: true,
			}
			break
		case 'text':
		case 'email':
			fieldParams = {
				...fieldParams,
				noMargin: true,
			}
			break
		default:
	}

	return (
		<Fragment key={`${field.name}-${field.value}`}>
			<div className={b('field', { type: field.type })}>
				<Field {...fieldParams} />
				{['checkbox', 'radio'].indexOf(field.type) !== -1 && typeof priceField !== 'undefined' && (
					<div className={b('field-price')}>
						<div className={b('field-price-value')}>
							{priceFieldPrefix}
							{numberFormat(priceField)} <Ruble />
							{priceFieldPostfix}
						</div>
					</div>
				)}
			</div>
			{options.fields && checked && (
				<div className={b(`inside-${field.size || 'md'}`)}>
					{options.fields.map(options => formField(options, formProps))}
				</div>
			)}
		</Fragment>
	)
}

// Отфильтрованные результаты калькулятора
const getValues = (services, values, rounding) => {
	let total = 0

	const filter = (item, values) => {
		const { field, fields, price, priceCalc } = item
		const name = field.name
		const value = values[name]

		const checked = {
			checkbox: () => value === true,
			radio: () => value === field.value,
			slider: () => true,
			text: () => false,
			email: () => false,
		}[field.type]()

		if (!checked) return

		let res = { [name]: value }

		fields &&
			fields.map(item => {
				res = { ...res, ...filter(item, values) }
				return null
			})

		if ((price || priceCalc) && typeof value !== 'undefined') {
			const _price = priceCalc ? priceCalc(value, total) : price
			total += typeof value === 'number' ? _price * value : _price
		}

		return res
	}

	let res = {}

	services.map(item => {
		res = { ...res, ...filter(item, values) }
		return null
	})

	// Округляем до десятков
	total = Math.round(total / rounding) * rounding

	res = { total, ...res, ...values }

	res.partnerAi = getCookie('partnerAi', 'number')
	res.referralAi = getCookie('referralAi', 'number')

	return res
}

const Calc = props => {
	const { initialValues, services, schema, rounding, title } = props
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState(false)
	const [isSuccess, setIsSuccess] = useState(false)
	const { siteApiUrl } = useSiteMetadata()

	const [currentServiceName, setCurrentServiceName] = useState(null)

	const notificationsContext = useContext(NotificationsContext)

	// При переключении сервисов, скрываем сообщения отправки форм
	useEffect(() => {
		setIsSuccess(false)
		setIsError(false)
	}, [currentServiceName])

	const getService = values => services.find(v => v.field.value === values.type)

	return (
		<div className={b()}>
			{schema && <SchemaProduct {...schema} />}
			<Form
				onSubmit={async (values, form) => {
					setIsLoading(true)
					let response
					try {
						const service = getService(values)

						response = await fetch(`${siteApiUrl}${service.endpoint}`, {
							method: 'POST',
							headers: { 'Content-Type': 'application/json;charset=utf-8' },
							body: JSON.stringify(getValues(services, values, rounding)),
						})
						setIsLoading(false)

						const res = await response.json()

						if (response.status !== 200) {
							const { error = {} } = res
							notificationsContext.add({
								title: error.title || 'Возникла техническая ошибка',
								text: error.text || 'Мы уже знаем о проблеме. Пожалуйста, попробуйте позднее',
							})
							setIsError(true)
							return
						}

						// setTimeout(() => form.reset({ ...initialValues, type: values.type }))

						setIsSuccess(true)
						setTimeout(() => setIsSuccess(false), 30 * 1000)
						setIsError(false)

						service.callback && service.callback(res)
					} catch (e) {
						notificationsContext.add({
							title: 'Проводятся технические работы',
							text: 'Некоторые функции сайта могут не работать',
						})
						setIsLoading(false)
						setIsError(true)
					}
				}}
				initialValues={initialValues}
				render={formProps => {
					const { handleSubmit, values } = formProps
					const calc = getValues(services, values, rounding)
					const service = getService(values)

					setCurrentServiceName(service.field.value)

					const inners = {
						contactForm: props => (
							<ContactForm
								service={service.field.value}
								{...props}
								isLoading={isLoading}
								isSuccess={isSuccess}
								isError={isError}
							/>
						),
						custom: () => service.inner.component.render({ isLoading, isSuccess, isError, values: calc }),
					}
					const Inner =
						service.inner.component.type === 'custom'
							? inners.custom()
							: inners[service.inner.component.type](service.inner.component.props || {})

					const Title = service.title && <h2 className="mb">{service.title}</h2>
					const TextBefore = service.inner.textBefore && (
						<Secondary className="mb">{service.inner.textBefore}</Secondary>
					)
					const TextAfter = service.inner.textAfter && <Secondary className="mb">{service.inner.textAfter}</Secondary>
					const Price = service.isShowPrice && (
						<div className={b('price-wrapper', 'mb')}>
							<div className={b('price')}>
								{service.pricePrefix && <span className="text-lg">{service.pricePrefix}</span>}
								<Increment isFormat>{calc.total}</Increment>
								<Ruble />
							</div>
							{service.priceOld && (
								<div className={b('price-old-wrapper')}>
									<div className={b('price-old')}>
										<Ruble>{service.priceOld}</Ruble>
									</div>
									<Mark className={b('discount')} bold color="green">
										&minus;{Math.ceil(100 - (service.price * 100) / service.priceOld)}%
									</Mark>
								</div>
							)}
						</div>
					)

					return (
						<form onSubmit={handleSubmit}>
							<Grid fluid>
								<Row>
									<Col sm={10} smOffset={1} md={6} mdOffset={0} lg={6}>
										<div className={b('services')}>
											<h2 className="mb-lg">{title}</h2>
											{services.map(service => formField(service, formProps))}

											{/*<pre>{JSON.stringify(calc, 0, 2)}</pre>*/}
										</div>
									</Col>
									<Col sm={10} smOffset={1} md={5} mdOffset={1} lg={5} lgOffset={1}>
										<div className={b('total')}>
											{Title}
											{Price}
											{TextBefore}
											{Inner}
											{TextAfter}
										</div>
									</Col>
								</Row>
							</Grid>
						</form>
					)
				}}
			/>
		</div>
	)
}

Calc.defaultProps = {
	rounding: 10,
	help: {},
	button: {},
	notification: {},
	inner: {},
}

Calc.propTypes = {
	// Заголовки
	title: PropTypes.string,

	// Округление цены
	rounding: PropTypes.number,

	// Параметры по умолчанию
	initialValues: PropTypes.object,

	// Разделы
	services: PropTypes.arrayOf(
		PropTypes.shape({
			field: PropTypes.object.isRequired, // Поле
			title: PropTypes.string, // Заголовок в правом блоке
			price: PropTypes.number, // Цена
			priceOld: PropTypes.number, // Старая цена
			pricePrefix: PropTypes.string, // Префикс перед ценой в правом блоке
			priceField: PropTypes.number, // Цена выводимая рядом с полем (для checkbox, radio)
			priceFieldPrefix: PropTypes.string, // Префикс перед ценой выводимой рядом с полем (для checkbox, radio)
			isShowPrice: PropTypes.bool, // Нужно ли выводить цену в правом блоке
			count: PropTypes.string, // Имя поля слайдара для вывода количества в заголовке поля

			// Дочерние поля
			fields: PropTypes.arrayOf(
				PropTypes.shape({
					field: PropTypes.object.isRequired, // Поле
					price: PropTypes.number, // Цена
				}).isRequired
			),

			// Содержимое правого блока
			inner: PropTypes.shape({
				component: PropTypes.shape({
					type: PropTypes.oneOf(['custom', 'contactForm']).isRequired,
					render: PropTypes.func, // type = custom
					props: PropTypes.object, // type != custom
				}),
				textBefore: PropTypes.oneOfType([PropTypes.object, PropTypes.string]), // Текст перед компонентом
				textAfter: PropTypes.oneOfType([PropTypes.object, PropTypes.string]), // Текст после компонента
			}).isRequired,

			// Конечная точка API
			endpoint: PropTypes.string,
		})
	).isRequired,
}

export default Calc
