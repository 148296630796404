import Cookies from 'universal-cookie'

/**
 * Установка куки
 * @param name - название
 * @param value - значение
 * @param days - дней жизни
 */
export const setCookie = (name, value, days = 31) => {
	const cookies = new Cookies()
	const expires = new Date()
	expires.setDate(expires.getDate() + days)
	cookies.set(name, value, { path: '/', expires })
}

/**
 * Получение куки
 * @param name - название
 * @param type - возвращаемый тип
 * @returns {number|string|undefined}
 */
export const getCookie = (name, type = 'string') => {
	const getFromType = v => {
		if (type === 'number') return Number(v)
		// ... other types
		return v
	}
	const cookies = new Cookies()
	const referrer = cookies.get(name)
	return referrer ? getFromType(referrer) : undefined
}
