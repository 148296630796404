import React from 'react'
import bem from 'bem'
import css from './Table.module.scss'
import PropTypes from 'prop-types'

const b = bem.table(css)

const Table = props => {
	const { columns, source, hiddenOnMobile, priorityWidth, size } = props
	return (
		<div className={b()}>
			<table className={b('table', { size })}>
				<thead>
					<tr>
						{columns.map(({ key, title, className }) => {
							return (
								<td key={key} className={className}>
									{title}
								</td>
							)
						})}
					</tr>
				</thead>
				<tbody>
					{source.map((row, rowIndex) => {
						const cols = columns.map(column => ({
							key: column.key,
							text: row[column.key],
						}))

						const { options = {} } = row
						const { row: rowOptions = {} } = options
						const { background, class: rowClassName } = rowOptions

						return (
							<tr
								key={`table-row-${rowIndex}`}
								className={b('row', { 'is-no-bg': background === false }, rowClassName)}
							>
								{cols.map((col, colIndex) => {
									let classes = []
									if (hiddenOnMobile.indexOf(col.key) !== -1) classes.push('hidden-xs')
									if (priorityWidth.indexOf(col.key) !== -1) classes.push(b('column-priority'))

									return (
										<td key={`table-col-${colIndex}`} className={classes}>
											{col.text}
										</td>
									)
								})}
							</tr>
						)
					})}
				</tbody>
			</table>
		</div>
	)
}

Table.defaultProps = {
	columns: [],
	source: [],
	hiddenOnMobile: [],
	priorityWidth: [],
}

Table.propTypes = {
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		}).isRequired
	).isRequired,

	source: PropTypes.arrayOf(PropTypes.object).isRequired,
	size: PropTypes.oneOf(['lg', 'xl']),
	hiddenOnMobile: PropTypes.arrayOf(PropTypes.string),
	priorityWidth: PropTypes.arrayOf(PropTypes.string),
}

export default Table
