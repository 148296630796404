import React from 'react'
import bem from 'bem'
import css from './AdaptersError.module.scss'

const b = bem.adaptersError(css)

const AdaptersError = ({ children, className, negative, ...rest }) => {
	return (
		<div className={b({ negative }, className)} {...rest}>
			{children}
		</div>
	)
}

export default AdaptersError
