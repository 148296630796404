import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './HelperButton.module.scss'
import Icon from 'components/Icon'

const b = bem.helperButton(css)

const HelperButton = props => {
	const { active, negative, className, onClick = () => {} } = props
	return (
		<button
			type="button"
			className={b({ active, negative }, className)}
			onClick={onClick}
			onKeyDown={() => {}}
			aria-label="Help"
			tabIndex={0}
		>
			<Icon type="question" size={20} />
		</button>
	)
}

HelperButton.propTypes = {
	active: PropTypes.bool,
	negative: PropTypes.bool,
	className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	onClick: PropTypes.func,
}

export default HelperButton
