/**
 * Форматирование числа
 * @param value
 * @param options
 * @returns {string|number|*}
 */
const numberFormat = (value, options = {}) => {
	const { isThousand, n = 0, x = 3, s = ' ', c = '.' } = options
	if (typeof value !== 'number') return value
	if (!isThousand && value < 10000) return value
	const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
		num = value.toFixed(Math.max(0, ~~n))

	return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','))
}

module.exports = {
	numberFormat,
}
