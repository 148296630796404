import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import objectsDiff from 'utils/objectsDiff'
import HelperButton from 'components/HelperButton'
import Secondary from 'components/Secondary'
import css from './AdapterRadio.module.scss'
import Icon from 'components/Icon'

const b = bem.adapterRadio(css)

/**
 * Использование
 *
 <Field
 component={AdapterRadio}
 type="radio"
 name="radio"
 children="Один"
 validate={validators.compose(
		validators.isTrue,
	)}
 />
 */
const AdapterRadio = props => {
	const {
		noMargin,
		noError,
		onChange,
		onFocus,
		onBlur,
		input,
		meta,
		children,
		helper,
		isHelperStatic,
		negative,
		size,
		disabled,
		...rest
	} = props
	const [isHelp, setIsHelp] = useState(isHelperStatic)
	const isError = meta.error && meta.touched

	const radioProps = {
		...input,
		...rest,
		disabled,
		onChange: e => {
			input.onChange(e)
			if (onChange) onChange(e)
		},
		onFocus: e => {
			input.onFocus(e)
			if (onFocus) onFocus(e)
		},
		onBlur: e => {
			input.onBlur(e)
			if (onBlur) onBlur(e)
		},
	}
	return (
		<div
			className={b({
				'is-error': isError,
				'is-checked': !!input.checked,
				'is-no-margin': noMargin,
				'is-negative': negative,
				'is-helper': !!helper,
				'is-disabled': disabled,
				size,
			})}
		>
			<div className={b('wrap')} title={isError ? meta.error : ''}>
				<label className={b('label')}>
					<span className={b('icon')}>
						<Icon className={b('check')} type="check" />
					</span>
					<input className={b('field')} {...radioProps} />
					<span dangerouslySetInnerHTML={{ __html: children }} />
				</label>
				{helper && !isHelperStatic && (
					<HelperButton
						className={b('helper-button')}
						onClick={() => setIsHelp(!isHelp)}
						active={isHelp}
						negative={negative}
					/>
				)}
			</div>
			<Secondary className={b('help', { active: isHelp })} negative={negative}>
				<div dangerouslySetInnerHTML={{ __html: helper }} />
			</Secondary>
		</div>
	)
}

AdapterRadio.propTypes = {
	noMargin: PropTypes.bool,
	noError: PropTypes.bool,
	negative: PropTypes.bool,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	helper: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	isHelperStatic: PropTypes.bool,
	size: PropTypes.oneOf(['lg']),
}

export default memo(
	AdapterRadio,
	(prevProps, nextProps) =>
		!(
			prevProps.input.checked !== nextProps.input.checked ||
			objectsDiff(prevProps.meta, nextProps.meta) ||
			prevProps.children !== nextProps.children
		)
)
