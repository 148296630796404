import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Mark.module.scss'

const b = bem.mark(css)

const Mark = props => {
	const { color, fill, bold, children, className } = props
	return <span className={b({ color, fill, bold }, className)}>{children}</span>
}

Mark.defaultProps = {
	color: 'default',
}

Mark.propTypes = {
	color: PropTypes.oneOf(['default', 'green', 'red', 'blue']),
	fill: PropTypes.bool,
	bold: PropTypes.bool,
}

export default Mark
