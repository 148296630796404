import React from 'react'
import PropTypes from 'prop-types'
import Hint from 'rc-tooltip'
import './Tooltip.scss'

const Tooltip = ({ children, ...rest }) => {
	return <Hint {...rest}>{children}</Hint>
}

Tooltip.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Tooltip
