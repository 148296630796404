import React from 'react'
// import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import AdapterText from 'components/Adapter/Text'
import AdapterTextArea from 'components/Adapter/TextArea'
import v from 'utils/validators'
import Button from 'components/Button'
import FormMessage from 'components/FormMessage'

const ContactForm = props => {
	const {
		service,
		fields,
		isLoading,
		isSuccess,
		isError,
		buttonTitle,
		buttonTitleSuccess,
		buttonTheme,
		buttonIcon,
		textSuccess,
		textFail,
	} = props
	return (
		<>
			{fields.map(field => {
				switch (field.type) {
					case 'name':
						return (
							<Field
								key={service + field.name}
								component={AdapterText}
								name={field.name}
								placeholder={field.placeholder || 'Ваше имя'}
								prefix="user"
								validate={field.required && v.compose(v.required)}
							/>
						)

					case 'phone':
						return (
							<Field
								key={service + field.name}
								component={AdapterText}
								name={field.name}
								type="tel"
								placeholder={field.placeholder || 'Телефон'}
								prefix="phone"
								mask="phone"
								validate={field.required && v.compose(v.required)}
							/>
						)

					case 'email':
						return (
							<Field
								key={service + field.name}
								component={AdapterText}
								name={field.name}
								type="email"
								placeholder={field.placeholder || 'Email-адрес'}
								prefix="mail"
								validate={field.required && v.compose(v.required)}
							/>
						)

					case 'text':
						return (
							<Field
								key={service + field.name}
								component={AdapterText}
								name={field.name}
								placeholder={field.placeholder}
								validate={field.required && v.compose(v.required)}
								prefix={field.icon}
							/>
						)

					case 'textarea':
						return (
							<Field
								key={service + field.name}
								component={AdapterTextArea}
								name={field.name}
								placeholder={field.placeholder}
								minRows={2}
								maxRows={10}
								validate={field.required && v.compose(v.required)}
							/>
						)

					default:
						return null
				}
			})}
			<p className="no-last">
				<Button
					type="submit"
					theme={buttonTheme || 'primary'}
					icon={buttonIcon}
					loading={isLoading}
					disabled={isLoading || isSuccess}
				>
					{!isSuccess ? buttonTitle : buttonTitleSuccess}
				</Button>
			</p>
			{isError && (
				<FormMessage error>
					{textFail || 'Произошла техническая ошибка, скоро исправим. Попробуйте немного позднее'}
				</FormMessage>
			)}
			{isSuccess && <FormMessage success>{textSuccess}</FormMessage>}
		</>
	)
}

ContactForm.defaultProps = {
	fields: [],
}

// ContactForm.propTypes = {
// 	fields: PropTypes.shape({
// 		name: PropTypes.shape({
// 			required: PropTypes.bool,
// 		}),
// 		phone: PropTypes.shape({
// 			required: PropTypes.bool,
// 		}),
// 		email: PropTypes.shape({
// 			required: PropTypes.bool,
// 		}),
// 		message: PropTypes.shape({
// 			required: PropTypes.bool,
// 		}),
// 	}).isRequired,
// }

export default ContactForm
