import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import usePrevious from 'hooks/usePrevious'
import { numberFormat } from 'utils/numberFormat'

const Increment = ({ children, isFormat }) => {
	const max = parseInt(children)
	const min = usePrevious(max)
	const steps = 50 // Количество шагов
	const timeStep = 10 // Время одного шага
	const adaptiveTimeStep = true // Изменять время шага, если разница в значениях меньше количества шагов
	const interval = useRef(0)

	const [value, setValue] = useState(max)

	/* eslint-disable */
	useEffect(() => {
		if (min !== null && min !== max) {
			const diff = max - min
			const direction = diff > 0 ? 1 : -1
			let inc = diff / steps

			// По возрастанию
			if (diff > 0) inc = Math.ceil(inc)

			// По убыванию
			if (diff < 0) inc = Math.floor(inc)

			// Перерасчет времени шага
			const time = adaptiveTimeStep ? Math.ceil(((inc * steps) / diff) * timeStep) : timeStep

			// Удаление ранее установленного интервала
			clearInterval(interval.current)

			let current = min
			interval.current = setInterval(function() {
				current += inc

				if ((direction === 1 && current >= max) || (direction === -1 && current <= max)) {
					current = max
					clearInterval(interval.current)
				}

				setValue(current)
			}, time)
		}

		return () => clearInterval(interval.current)
	}, [max])
	/* eslint-enable */

	return isFormat ? numberFormat(value) : value
}

Increment.propTypes = {
	children: PropTypes.number.isRequired,
	isFormat: PropTypes.bool,
}

export default Increment
