const compose = (...validators) => value =>
	validators.reduce((error, validator) => error || validator(value), undefined)

// prettier-ignore
const required = (value, message) => {
	const valid = Array.isArray(value)
		? value.length
		: value || value === 0 || value === false

	return valid ? undefined : message || 'Обязательное поле'
}

const number = (value, message) => (value && isNaN(value) ? 'Должно быть числом' || message : undefined)

const min = min => value => (isNaN(value) || value === null || value >= min ? undefined : `Не меньше ${min}`)

const max = max => value => (isNaN(value) || value === null || value <= max ? undefined : `Не больше ${max}`)

const url = value => (/^[a-z0-9-]+$/.test(value) ? undefined : 'Должно содержать символы: a-z, 0-9, -')

const passwordConfirm = ({ password, passwordConfirm }) =>
	password && passwordConfirm && password === passwordConfirm ? undefined : 'Пароли не совпадают'

const passwordLength = password => (password.length >= 6 ? undefined : 'Пароль должен быть не менее 6 символов')
const passwordMatch = password => (!/\s/.test(password) ? undefined : 'Пароль не должен содержать пробелы')

const isTrue = (value, message) => (value !== true ? 'Обязательное поле' || message : undefined)

export default {
	compose,
	required,
	number,
	min,
	max,
	url,
	passwordConfirm,
	passwordLength,
	passwordMatch,
	isTrue,
}
