import React, { useContext, useEffect, useRef } from 'react'
import bem from 'bem'
import css from './Promo.module.scss'
import { Field, Form } from 'react-final-form'
import AdapterText from 'components/Adapter/Text'
import WithAutoSaveDebounce from 'containers/WithAutoSaveDebounce'
import StoreContext from 'contexts/store'
import usePrevious from 'hooks/usePrevious'

const b = bem.sceneCartPromo(css)

const Promo = () => {
	const storeContext = useContext(StoreContext)
	const { name, isActual, message, loading } = storeContext.data.promo || {}
	const disabled = loading || storeContext.data.loading

	// После установки disabled на поле сбрасывается фокус
	// Установка повторно после загрузки
	const formRef = useRef(null)
	const loadingPrev = usePrevious(loading)
	const id = 'cartPromoField'
	useEffect(() => {
		const field = formRef.current.querySelector('#' + id)
		if (loadingPrev && !loading && !isActual) {
			field.focus()
		}
	}, [loadingPrev, loading, isActual])

	return (
		<div className={b()}>
			<Form
				onSubmit={() => {}}
				initialValues={{ name }}
				render={formProps => {
					const { handleSubmit, values } = formProps
					return (
						<form onSubmit={handleSubmit} ref={formRef}>
							<WithAutoSaveDebounce debounce={1000} save={() => storeContext.usePromo(values.name)} />
							<div className={b('inner')}>
								<div className={b('field')}>
									<Field
										id={id}
										component={AdapterText}
										name="name"
										placeholder="Промо-код"
										prefix={loading ? 'loading' : 'label'}
										disabled={disabled}
										autoComplete="off"
										size="sm"
										noMargin
									/>
								</div>
								{isActual && <div className={b('message', { status: 'success' })}>Промо-код активирован</div>}
								{!isActual && message && <div className={b('message', { status: 'fail' })}>{message}</div>}
							</div>
						</form>
					)
				}}
			/>
		</div>
	)
}

Promo.propTypes = {}

export default Promo
